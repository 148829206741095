
function filterFactoryType(uuid) {
    const ruiyin_base64 = 'cnVpeWlu';
    const lexing_base64 = 'bGV4aW5n';
    const yingwo_base64 = 'eWluZ3dv';
    const lansong_base64 = 'bGFuc29uZw==';
    const wangbo_base64 = 'd2FuZ2Jv';
    const haopai_base64 = 'aGFvcGFp';
    let res = '';

    ruiyin_base64 === uuid && (res = 'ruiyin');
    lexing_base64 === uuid && (res = 'lexing');
    yingwo_base64 === uuid && (res = 'yingwo');
    lansong_base64 === uuid && (res = 'lansong');
    haopai_base64 === uuid && (res = 'haopai');
    // 旺博投料机
    if (wangbo_base64 === uuid) {
        res = 'wangbo'
        window.fType = 5;
    }
    window.fTypeText = res;
    return res
}

function filterOrderStatus(type) {
    let res = '-'
    type == 1 && (res = '待质检')
    type == 2 && (res = '待确认')
    type == 3 && (res = '待复核')
    type == 4 && (res = '已完成')
    type == 5 && (res = '已结算')
    return res
}

/**
 * 十进制转换为二进制
 * @param {string} num   十进制数值
 * @param {string} bit         二进制的bit位数
 */
function dec2bin(num, Bits) {
    const resArry = [];
    const xArray = [];
    let i = 0;
    //除2取余
    for (; num > 0;) {
        resArry.push(num % 2);
        num = parseInt(num / 2);
        i++;
    }
    //倒序排列
    for (let j = i - 1; j >= 0; j--) {
        xArray.push(resArry[j]);
    }
    //报错
    if (Bits < xArray.length) {
        console.log("控制位小于转换位数");
    }
    //补0操作
    if (Bits) {
        for (let r = xArray.length; r < Bits; r++) {
            xArray.unshift("0");
        }
    }
    return xArray.join().replace(/,/g, "");
}

function filterWarningTypeText(warningType) {
    let warnText = '';
	warningType == 1 && (warnText = '电流过载');
	warningType == 2 && (warnText = '开关缺相');
	warningType == 3 && (warnText = '异常停机');
	warningType == 4 && (warnText = '电压异常');
	warningType == 5 && (warnText = '电压异常');
	warningType == 7 && (warnText = '断电');
	warningType == 10 && (warnText = '开关异常');
	warningType == 31 && (warnText = '设备失联');
	warningType == 36 && (warnText = '电流过低');
	warningType == 37 && (warnText = '异常停机');
	warningType == 38 && (warnText = '异常停机');
	warningType == 39 && (warnText = '异常停机');
	warningType == 40 && (warnText = '异常停机');
	warningType == 41 && (warnText = '异常停机');
	warningType == 42 && (warnText = '异常停机');
	warningType == 43 && (warnText = '异常停机');
	warningType == 44 && (warnText = '异常停机');
	warningType == 45 && (warnText = '进线缺相');
	warningType == 46 && (warnText = '异常停机');
	warningType == 47 && (warnText = '异常停机');
	warningType == 48 && (warnText = '进线电压不一致');
	warningType == 49 && (warnText = '进线电压缺相');

	warningType == 50 && (warnText = '电机卡壳');
    warningType == 52 && (warnText = '气压异常');
	warningType == 70 && (warnText = '溶氧过低');
	warningType == 72 && (warnText = '电解液不足');
	warningType == 73 && (warnText = '探头未接触水面');
	warningType == 74 && (warnText = '测量受到干扰');
	// 饱和氧偏差过大
	warningType == 83 && (warnText = '测量结果异常');

	warningType == 75 && (warnText = '长时间未上报溶氧');
	warningType == 76 && (warnText = '设备故障');
	warningType == 77 && (warnText = '探头泡水');
	warningType == 78 && (warnText = '管道漏气');
	warningType == 79 && (warnText = '探头受污染');
	warningType == 82 && (warnText = '自动开机失败');
    return warnText
}

function filterHandlingMethod(type) {
    let text = '-'
    type == 1 && (text = '翻新')
    type == 2 && (text = '报废')
    type == 3 && (text = '补新')
    return text
}

// 枚举探头售后单状态
function filterProbeOrderStatus(status) {
    const list = {
        0: '待确认',
        1: '待复核',
        2: '已确认',
        3: '已结算',
        4: '已完成',
    }
    return list[Number(status)]
}

// 枚举责任方，4：英沃 5:览宋
function filterRespText(status) {
    const list = {
        1: '鱼大大',
        2: '瑞银',
        3: '旺博',
        5: '览宋',
        4: '英沃'
    }
    return list[Number(status)]
}

// 6:4G荧光法标准版，7:4G荧光法深水版，9:2G荧光法标准版，11:2G荧光法深水版
// 3：电解法标准版，4：电解法深水版
function filterRyyDeviceName(type) {
    const list = {
        3: '电解法溶氧仪(标准版)',
        4: '电解法溶氧仪(深水版)',
        6: '荧光法溶氧仪(4G标准版)',
        7: '荧光法溶氧仪(4G深水版)',
        9: '荧光法溶氧仪(2G标准版)',
        11: '荧光法溶氧仪(2G深水版)',
        13: '荧光法溶氧仪(一代4G标准版)',
        14: '荧光法溶氧仪(一代4G深水版)',
    }
    return list[Number(type)] || ''
}

// 重新定义设备类型枚举
// 0,1,5,10,20,21 => 1控制器
// 2 => 2投料机
// 3,4,6 => 3溶氧仪
// 61 => 6摄像头
// 101,102 => 100增氧机
const resetDeviceType = (deviceType) => {
    const type = Number(deviceType);
	let dType = 0;
	// 控制器
	[0, 1, 5, 10, 20, 21, 71 ,72 ,73].indexOf(type) > -1 && (dType = 1);
	// 投料机
	[2, 8, 30, 31, 32, 33, 34, 35, 36, 37, 38, 40, 41 ,42, 43, 44, 45].indexOf(type) > -1 && (dType = 2);
	// 溶氧仪
	[3, 4, 6, 7, 9, 11, 13, 14, 15, 16, 17, 18, 19, 22].indexOf(type) > -1 && (dType = 3);
	// 鱼大大增氧机
	[101, 102, 103, 104, 105].indexOf(type) > -1 && (dType = 100);
	// 鱼大大摄像头
	[61, 62, 63, 64].indexOf(type) > -1 && (dType = 6);
	return dType;
};

export {
    filterFactoryType,
    filterOrderStatus,
    dec2bin,
    filterWarningTypeText,
    filterHandlingMethod,
    filterProbeOrderStatus,
    filterRespText,
    filterRyyDeviceName,
    resetDeviceType
}